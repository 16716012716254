import React from "react";
import {
  COMPANY_NAME,
  WHITE_COMPANYADDRESS,
  WHITE_COMPANYFULLNAME,
} from "../../../../utils/ApiUrls";

const LogisticsServicesAgreement: React.FC = () => {
  return (
    <div>
      <h1 className="text-center font-Open text-base font-semibold leading-[22px] mb-3">
        Logistics Services Agreement
      </h1>
      <p className="font-Open text-base font-normal leading-5">
        This Logistics Services Agreement contains terms and conditions between
        You/User Company/Individual/Firm/Partnership/Body Corporate (hereinafter
        referred to as "Customer), and{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {WHITE_COMPANYFULLNAME || "AVN Business solutions PVT Ltd."}
        </strong>
        , A company registered under the Companies Act, 1956, having its
        corporate office at{" "}
        {WHITE_COMPANYADDRESS ||
          "12A, 3rd Floor, Techniplex - II, Techniplex Complex, S.V.Road, Off Veer Savarkar Flyover, Goregaon West, Mumbai, Maharashtra 400062"}{" "}
        (hereinafter referred to as "Service Provider”), which means and
        include, unless repugnant to the context or meaning thereof mean and
        include its affiliates, liquidators, successors, receivers and permitted
        assigns) of the other part
      </p>
      <br />
      <p className="font-Open text-base font-normal leading-5">
        By registering for or using the services, you (on behalf of yourself or
        the business you represent) agree to be bound by the terms of this
        agreement, including the service terms and conditions for each service
        you register for or use on{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        website or{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        mobile application.
      </p>
      <br />

      <p className="font-Open text-base font-normal leading-5">
        You are advised to read this Agreement carefully. You expressly
        represent and warrant that you will not avail the Services if you do not
        understand, agree to become a party to, and abide by all the terms and
        conditions specified below. Any violation of this Agreement may result
        in legal liability upon you.
      </p>
      <br />

      <p className="font-Open text-base font-normal leading-5">
        Your access to use the Services will be solely at the discretion of{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>
        .
      </p>
      <br />

      <p className="font-Open text-base font-normal leading-5">
        Now This Agreement Witnessed as Under:
      </p>
      <br />

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        Definitions
      </h2>
      <br />

      <p className="font-Open text-base font-normal leading-5">
        a. ‘Confidential information’ means all non-public information disclosed
        by a party (Disclosing party) to the other party (Receiving party), that
        is designated as confidential or that reasonably should be understood to
        be confidential given the nature of the information and the
        circumstances of disclosure.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        b. ‘COD’ (cash on delivery) means the cash payment made by the Recipient
        for the purchase of the products on the platform operated by the
        customer upon delivery of such consignment/s.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        c. ‘Consignment/Shipment’ means the customer’s goods consolidated,
        packaged and/or invoiced together and handed over to the service
        provider by the customer for delivery.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        d. ‘Manifest’ means the data uploaded by the customer (in the format
        shared by the{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>
        ) containing the details of the shipment for which the service provider
        is to provide delivery services or cash pickup services as the case may
        be.
      </p>
      <br />
      <h2 className="font-Open text-base font-semibold leading-[22px]">
        1. Scope Of Services
      </h2>
      <br />

      <p className="font-Open text-base font-normal leading-5">
        1.1. This master agreement governs one or more e-commerce logistics
        services offered by <strong>{COMPANY_NAME || "Shipyaari"}</strong> to
        Customer.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        1.2. Detailed scope of services in Annexure I.
      </p>
      <br />

      <h2 className="font-bold">2. Fees</h2>
      <br />

      <p className="font-Open text-base font-normal leading-5">
        2.1. Subject to the provisions of this Agreement, the Customer will pay
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        the fees and other amounts as mentioned against each service more
        particularly described in Annexure II for Domestic Logistics, Annexure
        III for International Services and Annexure IV for Fulfilment and
        Logistics services respectively.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        2.2. Payment of fees for pre-paid customer-The payment for the services
        availed by the customer shall be made in advance at the time the
        customer makes a request for the service.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        2.3.{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        may add new services for additional fees and charges or may amend fees
        and charges for existing services, at any time in its sole discretion.
        Any change in the rate becomes effective from the date when{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        intimates the customer of such rate revision.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        2.4.{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        is registered under MSME and late payments shall attract penalty as
        specified under MSMED Act 2006 (The Micro, Small and Medium Enterprises
        Development Act, 2006).
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        3. Obligations Of the Customer
      </h2>
      <br />

      <p className="font-Open text-base font-normal leading-5">
        3.1. The customer shall not book/handover or allow to be handed over any
        consignment(s) that are banned, restricted, illegal or prohibited,
        stolen, infringing any third party rights, hazardous, or dangerous or in
        breach of any laws in force.{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        shall not be liable for the Delivery of any such Shipments. Without
        prejudice to the generality of the aforesaid, an indicative list of the
        banned or prohibited products is given in Annexure V.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        3.2. Any person delivering any dangerous, hazardous or banned goods to
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        or causing{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        to handle or deal with any such goods shall be liable for all loss or
        damage caused thereby and shall indemnify{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        against all penalties, claims, damages, costs and expenses arising in
        connection therewith and the goods may be destroyed or otherwise dealt
        with at the sole discretion of{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        or any other person in whose custody they may be at the relevant time.
        The expression “goods” likely to cause “damage” include goods likely to
        harbor or encourage vermin or other pests.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        3.3. The Customer warrants and represents that any information customer
        gives to{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        will always be true, accurate, correct, complete and up to date, to the
        best of his/her knowledge.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        3.4. Customer shall ensure that the manifest is being filled in for
        initiation of the delivery services, and that the information stated
        therein is correct and complete in all respects.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        3.5. Customer shall ensure that the packaging of the consignment is
        safe, proper and adequate to withstand the normal transportation and
        environmental hazards and in compliance with applicable laws.{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>
        shall not be liable or responsible for any loss or damages to the
        Customer if the Customer is not in strict compliance with this Clause.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        3.6. GST E way bill is an electronic way bill for movement of goods
        which Government has introduced on GSTN portal. Customer shall ensure
        compliance as prescribed under GST based on consignment value and
        applicability. Any non-compliance in generation of e-way bill by the
        customer may result in delay/non delivery of shipments booked.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        3.7.{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        will not accept or deal with any noxious, dangerous, hazardous or
        inflammable or explosive goods or any goods likely to cause damage.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        4. Undelivered Shipments/Consignments
      </h2>

      <p className="font-Open text-base font-normal leading-5">
        4.1. A shipment shall be considered to be undeliverable if (i) the
        Recipient's address is incomplete, illegible, incorrect or cannot be
        located, (ii) the Recipient fails or refuses to accept delivery or sign
        or pay for delivery of any consignment (iii) the consignment would
        likely cause damage or delay to other shipments or goods, or injury to
        persons, (iv) the consignment contains prohibited items, (v) the
        consignment's contents or packaging are damaged to the extent that
        re-wrapping is not possible, (vi) the consignment is detained or
        otherwise unable to clear local or state boundaries, (vii) if a
        Recipient of a cash on delivery (COD) consignment fails or refuses on
        such delivery to pay the cod amount
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        5. Insurance
      </h2>

      <p className="font-Open text-base font-normal leading-5">
        5.1. Customer hereby undertakes and agrees that, should it desire, it
        shall insure the consignments from point of origin to point of final
        destination at his/her own cost.{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        shall not be liable for insuring the consignment.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        5.2. Upon specific request made by the customer,{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        may arrange insurance of consignment on behalf of customer for the
        declared value of goods in respect of loss of physical damage to such
        consignment(s).
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        shall be only responsible for the administrative facilitation and
        arrangement of the insurance policy. The policy will be subject to
        respective terms and conditions.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        6. Risk And Title
      </h2>

      <p className="font-Open text-base font-normal leading-5">
        6.1. All consignments shall be carried on a ‘Said to contain basis’.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        6.2. The customer shall remain owner of the goods in consignment
        transported by{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        at all times.{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        will act as a custodian, not being an agent of the customer.
      </p>
      <br />
      <h2 className="font-Open text-base font-semibold leading-[22px]">
        7. Confidentiality
      </h2>
      <br />

      <p className="font-Open text-base font-normal leading-5">
        7.1. Each party may be given access to confidential information from the
        other party to perform its obligations under this agreement. The parties
        undertake not to use any such confidential information, other than for
        purposes related to this agreement, and shall use their best efforts to
        keep confidential and not disclose to any third party save and except on
        a ‘need- to-know’ basis any confidential information of the other party.
        The provisions of this clause shall not apply to confidential
        information which:
        <p className="font-Open text-base font-normal leading-5">
          7.1.1. is or becomes part of the public domain without breach of this
          agreement by a party;
        </p>
        <p className="font-Open text-base font-normal leading-5">
          7.1.2. is lawfully in the possession of a party and subject to an
          existing agreement between the parties;
        </p>
        <p className="font-Open text-base font-normal leading-5">
          7.1.3. is independently developed by a party or acquired from a third
          party to the extent that it is acquired otherwise than as a result of
          breach of this clause and with the right to disclose the same.
        </p>
      </p>

      <p className="font-Open text-base font-normal leading-5">
        7.2. Parties agree that the customer has taken explicit consent of the
        data subjects on the means and purpose of processing of their personal
        information for the purpose of this Agreement. Seeking such consent of
        the data subject shall be the liability of the customer alone.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        7.3. Customer agrees and provides his consent to receive communications,
        correspondences, updates, notifications, etc. from{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        through email, SMS, Whatsapp and any other mode as agreed by the Parties
        from time to time.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        8. Indemnities
      </h2>

      <p className="font-Open text-base font-normal leading-5">
        The customer hereby agrees to indemnify, defend and hold{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>
        , its affiliates, officers, directors, employees, contractors,
        sub-contractors, consultants, licensors, other third party service
        providers, agents and representatives harmless from and against claims,
        demands, actions, liabilities, costs, interest, damages and expenses of
        any nature whatsoever (including all legal and other costs, charges and
        expenses) incurred or suffered (directly or indirectly) by{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        or its employees, on account of: (a) customer’s access to or use of
        services; (b) violation of this agreement or any terms of use of the
        services customer (and/or its officers, directors and employees); (c)
        violation of applicable law by the customer; (d) wrongful or negligent
        act or omission of customer (and/or its officers, directors and
        employees); (e) any third party action or claim made against{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>
        , by reason of any actions undertaken by the customer (and/or its
        officers, directors and employees); (f) any duties, taxes, octroi, cess,
        clearance charges and any other charge/levy by whatsoever name called,
        levied on the shipments.
      </p>

      <h2 className="font-bold">9. Limitation Of Liability</h2>

      <p className="font-Open text-base font-normal leading-5">
        9.1.{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        or any of its directors, employees, agents, contractors shall not be
        liable to the customer, whether in tort, contract or otherwise, for any
        consequential, indirect, punitive, exemplary or incidental damages, lost
        profits or lost business opportunities.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        9.2. The maximum liability of{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        and the sole and exclusive remedy of the customer for the damage or loss
        or theft or misappropriation of any consignment caused due to gross
        negligence or willful misconduct of{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        shall be as per Annexure II.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        10. Intellectual Property
      </h2>

      <p className="font-Open text-base font-normal leading-5">
        10.1. Each party shall exclusively own its intellectual property and
        neither party will have any claim or right to the intellectual property
        of the other by virtue of this agreement or the performance of any
        activities hereunder except as otherwise provided herein. Neither party
        will take any action or make any claim to any intellectual property
        belonging to the other party, whether during the term of this agreement
        or thereafter, which is inconsistent with this section. No right or
        license shall be implied by estoppel or otherwise, other than the rights
        and licenses expressly granted in this section.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        10.2. Customer agrees that{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        may use customer’s name and logo on their website and social media
        platform with the intent to list the customer as a customer of{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>
        . It is clarified that all rights, title and interest in the logo and
        name remain with the Customer.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        11. Term And Termination
      </h2>

      <p className="font-Open text-base font-normal leading-5">
        11.1. This agreement shall come into force on and from the date from
        which the customer starts procuring services in any form or capacity and
        shall remain in existence while the customer is a user of any of the
        services in any form or capacity, until terminated by either party in
        accordance with the provisions of this agreement.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        11.2. Either Party may terminate this Agreement by giving one month
        notice to the other Party.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        11.3. Either party may terminate this agreement immediately if either
        party is declared insolvent or bankrupt or is unable to pay its debts or
        a resolution is passed for winding up of such party.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        11.4. In case of non-payment of undisputed amounts within agreed
        timelines, <strong>{COMPANY_NAME || "Shipyaari"}</strong> will be
        permitted to terminate this Agreement effective immediately.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        12. Force Majeure
      </h2>

      <p className="font-Open text-base font-normal leading-5">
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        shall not be liable for any loss or damage to the consignment or a delay
        in picking up or delivery of the consignment or delay in delivery is
        caused due to acts of God, force majeure events including but not
        limited to strikes, riots, political or other disturbances, fire,
        floods, tsunami, pandemic, accident of the vehicle or caused due to any
        factors beyond the control of{" "}
        <strong>{COMPANY_NAME || "Shipyaari"}</strong>.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        13. Dispute Resolution
      </h2>
      <br />

      <p className="font-Open text-base font-normal leading-5">
        All disputes arising out of or in relation to this agreement, including
        any question regarding its existence, validity or termination, which
        cannot be amicably resolved by the parties within 15 days of being
        brought to their attention, such 15 (fifteen) day period is referred to
        as the "consultation period"), and if such dispute is still not
        resolved, the dispute shall be settled by arbitration governed by the
        provisions of Arbitration and Conciliation Act, 1996. The parties agree
        to mutually appoint a sole arbitrator. The venue/seat of arbitration
        shall be Mumbai and the language of arbitration shall be English.
      </p>
      <br />
      <h2 className="font-Open text-base font-semibold leading-[22px]">
        14. Jurisdiction
      </h2>
      <p className="font-Open text-base font-normal leading-5">
        This Agreement, the construction and enforcement of its terms and the
        interpretation of the rights and duties of the parties hereto shall be
        governed by the laws of India and shall be subject to the jurisdiction
        of courts in Mumbai.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        15. General
      </h2>
      <p className="font-Open text-base font-normal leading-5">
        Severability: In the event any one or more of the provisions of this
        agreement shall, for any reason, be held to be invalid, illegal or
        unenforceable, the remaining provisions of this agreement shall be
        unaffected, and the invalid, illegal or unenforceable provision(s) shall
        be replaced by a mutually acceptable provision(s), which being valid,
        legal and enforceable, comes closest to the intention of the parties
        underlying the invalid, illegal or unenforceable provision(s).
      </p>
      <br />

      <p className="font-Open text-base font-normal leading-5">
        Notice: Any notice provided for in this agreement shall be in writing
        and shall be sent by postage, prepaid registered post with
        acknowledgement due or by recognized courier service, email, to the
        relevant party at its address set out below:
      </p>

      <p className="font-Open text-base font-normal leading-5">
        In the case of notices to{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>
        : <br />
        <strong className="font-Open text-base font-semibold leading-[22px]">
          <br />
          {WHITE_COMPANYFULLNAME || "AVN Business solutions PVT Ltd."}
          <br />
          <div>
            <strong>
              {WHITE_COMPANYADDRESS ||
                "12A, 3rd Floor, Techniplex - II, Techniplex Complex, S.V.Road, Off Veer Savarkar Flyover, Goregaon West, Mumbai, Maharashtra 400062"}
            </strong>
          </div>
        </strong>
        <br />
        {COMPANY_NAME.toLowerCase() === "shipyaari" ? (
          <a href="email:legal@<strong>Shipyaari</strong>.com">
            legal@
            <strong className="font-Open text-base font-semibold leading-[22px]">
              {COMPANY_NAME.toLowerCase() || "Shipyaari"}
            </strong>
            .com
          </a>
        ) : (
          ""
        )}
      </p>

      <p className="font-Open text-base font-normal leading-5">
        Waiver: It is hereby agreed between the parties that any failure on the
        part of either party to enforce at any time or for any period of time
        the terms and conditions contained herein shall not be construed to be
        waiver of any of the terms and conditions or of the right thereafter to
        enforce each and every term and condition of this agreement.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        Independent relationship: This agreement is entered and executed by the
        parties on a principal-to-principal basis and is a contract for service.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        Assignment: no party shall assign this agreement or any of its rights
        and obligations hereunder without prior consent of the other party.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        <strong>Electronic Signatures -</strong> The parties recognize the use
        of simple electronic signatures as legally valid and binding for
        entering into agreements (including without limitation this agreement,
        any statement of work or related document), unless applicable law
        mandates any other form of execution.
      </p>

      <h2 className="text-blue-300 font-Open text-base font-semibold leading-[22px]">
        Annexure I Scope of work and Rate card
      </h2>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        1. Forward delivery of the shipments
      </h2>
      <br />
      <p className="font-Open text-base font-normal leading-5">
        1.1. The service provider will pick up and deliver from regions within
        its territory, the shipments on any business day as required by the
        Customer.
      </p>
      <p className="font-Open text-base font-normal leading-5">
        1.2. The service provider will make 3 (three) attempts to deliver the
        shipments to the Recipient as per the information shared in the manifest
        except where the address provided is wrong or in circumstance where the
        Recipient cancels the order placed. In such circumstances, the service
        provider shall inform the customer of the same and initiate returns
        within 24 hours.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        2. Returns/RTO of the shipments
      </h2>
      <br />
      <p className="font-Open text-base font-normal leading-5">
        Shipments which are not accepted by the Recipient for any reason
        whatsoever will be returned to the customer at the location(s) as
        specified by the customer. The customer shall specify such location(s)
        for each and every shipment in the manifest, which has the details of
        the shipments that are being handed over for forward delivery.
      </p>
      <p className="font-Open text-base font-normal leading-5">
        Any return and reverse shipment that fails to reach the customer
        location within 45 working days will be considered as lost.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        3. Reverse pickups
      </h2>
      <br />
      <p className="font-Open text-base font-normal leading-5">
        3.1. The service provider will make 2 (two) attempts to pick up the
        shipments from the Recipient at the location specified by the customer
        from the date of initiation of such request;
      </p>
      <p className="font-Open text-base font-normal leading-5">
        3.2. The service provider shall seal the package provided by the
        Recipient at the time of pickup in front of the Recipient. The sole
        responsibility of the contents of the packed consignment shall lie with
        the Recipient.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        4. POD
      </h2>
      <br />
      <p className="font-Open text-base font-normal leading-5">
        It is understood that at the time of delivering the shipments in case of
        forward delivery, returns and/ or reverse pickups, the service provider
        shall obtain the signature (either physical, digital or OTP based
        verification) of the customer or his representative on a document which
        is referred to as a proof of delivery <strong>("POD").</strong> The
        service provider will not bear any liability on the shipment once the
        same has been delivered to the customer. The service provider shall make
        available to the customer, any PODs as may be required by the customer
        if such request is raised within 48 (forty-eight) hours of the date of
        delivery of the shipment. The service provider will be under no
        obligation to provide proof of delivery of the shipments beyond such
        period.
      </p>
      <p className="font-Open text-base font-normal leading-5">
        Further, in the event the customer instructs the service provider to
        deliver a shipment(s) to a Recipient on a COD basis, the service
        provider will simultaneously with such delivery of the shipment(s) to
        the customer collect cash (only in Indian rupees) of a value specified
        in the relevant invoice issued by the customer to the customer. Such
        cash will be held by the service provider as a limited agent of the
        customer and in trust for the customer and will be transferred by
        <strong>{COMPANY_NAME || "Shipyaari"}</strong> to the bank account
        designated by the customer within seven (7) to (fifteen) 15 business
        days after the day of collection of the cash based on agreed COD terms.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        5. Demurrage
      </h2>
      <br />
      <p className="font-Open text-base font-normal leading-5">
        All shipments picked up by the service provider from the customers will
        be returned to the customer at the location(s) as specified by the
        customer in the manifest uploaded by the customer at the time of
        initiation of such pickup. In case of non-acceptance of such shipments
        by the customer except for reasons such as damage of the product where
        such damage is attributable solely to the service provider, the service
        provider reserves the right to levy suitable demurrage charges for
        extended storage of such shipments for any period exceeding 7 (seven)
        days from initiation of the return of shipments and up to 45
        (forty-five) days from such date. After 45 days,{" "}
        <strong>{COMPANY_NAME || "Shipyaari"}</strong> shall have right to
        recover the demurrages by selling the shipment.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        6. Customer hereby agrees that it shall:
      </h2>
      <p className="font-Open text-base font-normal leading-5">
        6.1. Not (directly or indirectly) use{" "}
        <strong>{COMPANY_NAME || "Shipyaari"}</strong> platform while being in
        the capacity of a reseller, OTC (Over the Counter) or franchise of any
        courier/logistics company (including that of Blue Dart).
      </p>
      <p className="font-Open text-base font-normal leading-5">
        6.2. use the Blue Dart account/any other direct courier services through
        <strong>{COMPANY_NAME || "Shipyaari"}</strong> platform only for
        ecommerce sales related transactions; and
      </p>
      <p className="font-Open text-base font-normal leading-5">
        6.3. not already have a direct courier account since you understand that
        <strong>{COMPANY_NAME || "Shipyaari"}</strong> is not authorized to
        offer Blue Dart services to sellers who already hold Blue Dart account.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        In the event <strong>{COMPANY_NAME || "Shipyaari"}</strong> believes
        that you have breached any of the above provisions, then{" "}
        <strong>{COMPANY_NAME || "Shipyaari"}</strong> would inter alia, have
        the right to deactivate your service account, retain the custody of your
        shipment and levy damages/charges (along with applicable GST and
        freight) of INR 1,00,000 (Rupees One lakh only) per incident/consignment
        of such other amount as decided by{" "}
        <strong>{COMPANY_NAME || "Shipyaari"}</strong> in its sole discretion.
      </p>
      <h2 className="font-Open text-base font-semibold leading-[22px]">
        7. In case of damaged/pilfered/tempered/pressed/leaked consignment,
        Receiver shall mention negative
      </h2>
      <p className="font-Open text-base font-normal leading-5">
        remarks on POD copy to get claim for the consignment. In the absence of
        any negative remarks on POD copy clearly stating such
        damaged/pilfered/tempered/pressed/leaked, no claim shall be entertained
        by <strong>{COMPANY_NAME || "Shipyaari"}</strong>.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        8. <strong>{COMPANY_NAME || "Shipyaari"}</strong> shall not entertain
        any dispute regarding damaged/pilfered/tempered/pressed/leaked/non
      </h2>
      <p className="font-Open text-base font-normal leading-5">
        receipt of delivery after a period of 48 hours from the receipt/delivery
        of the said article/goods/shipment. Any claim needs to be submitted
        along with unpacking/packing video or other valid evidence to
        substantiate the claim. Further,{" "}
        <strong>{COMPANY_NAME || "Shipyaari"}</strong> shall not entertain any
        request if POD does not mention any remarks with respect to
        damaged/pilfered/tempered/pressed/leaked/non receipt.
      </p>

      <p className="font-Open text-base font-normal leading-5">
        9. For shipment of easily breakable goods, customer is required to
        submit an indemnity letter along. Also, a sticker mentioning “Fragile”
        needs to be compulsory stuck for such goods.{" "}
        <strong>{COMPANY_NAME || "Shipyaari"}</strong> will not be liable for
        any damage to such goods in absence of ‘Fragile’ sticker.
      </p>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        COD terms
      </h2>
      <table>
        <thead>
          <tr>
            <th>Express COD</th>
            <th>Transactional Fees</th>
            <th>Activation Fees</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>D+5</td>
            <td>0.00%</td>
            <td>10.00</td>
          </tr>
          <tr>
            <td>D+4</td>
            <td>0.25%</td>
            <td>250.00</td>
          </tr>
          <tr>
            <td>D+3</td>
            <td>0.50%</td>
            <td>500.00</td>
          </tr>
          <tr>
            <td>D+2</td>
            <td>0.75%</td>
            <td>750.00</td>
          </tr>
          <tr>
            <td>D+1</td>
            <td>1.00%</td>
            <td>1,000.00</td>
          </tr>
        </tbody>
      </table>
      <h2 className="font-Open text-base font-semibold leading-[22px]">
        Annexure II Domestic Services:
      </h2>
      <h3>Terms of payment</h3>
      <ul>
        <li>
          For Postpaid customer. The payment of service fees shall be made
          within seven (7) days of receipt of invoice. The invoice shall contain
          a detailed description of the services availed by the customer for the
          preceding month.
        </li>
        <li>
          There will be a minimum 10% increase in services in the fees every
          calendar year.
        </li>
        <li>
          If the customer defaults in making payment of the fees within due
          date, then <strong>{COMPANY_NAME || "Shipyaari"}</strong> shall levy a
          penalty on the unpaid invoiced amount at a rate equivalent to 2% per
          month.
        </li>
        <li>
          Customer shall raise any dispute in the invoice within 7 working days
          of receipt of invoice ("dispute date"). Notwithstanding anything
          contained above, all such disputes if raised within the dispute date
          shall be mutually resolved by the parties within 15 (fifteen) days and
          all pending payments by either party should be settled within 7
          (seven) days of the settlement of the dispute.
        </li>
        <li>Any charges recoverable from customer may be adjusted from COD.</li>
        <li>
          The maximum liability of{" "}
          <strong>{COMPANY_NAME || "Shipyaari"}</strong> and the sole and
          exclusive remedy of the customer for the damage or loss or theft or
          misappropriation of any consignment caused due to gross negligence or
          willful misconduct of <strong>{COMPANY_NAME || "Shipyaari"}</strong>{" "}
          shall be limited to INR 5000 (five thousand only) per consignment or
          the consignment value mentioned in manifest whichever is lower.
        </li>
        <li>
          The liability of the <strong>{COMPANY_NAME || "Shipyaari"}</strong>{" "}
          for any loss or damage/liquid damage to a Shipment for an amount
          higher than INR 5,000, shall be limited to providing the Customer with
          a Certificate of Fact (‘COF’) to enable Customer to claim the amount
          from its Insurance provider.
        </li>
      </ul>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        Annexure III International Services
      </h2>
      <h3>Terms & Conditions</h3>
      <ul>
        <li>
          Shipments/Consignments which cross national borders/ international
          shipments may be subject to customs clearance, customs, airport fees,
          surcharges prior to delivery to the recipient. These charges shall by
          payable by Customer.
        </li>
        <li>
          The Customer is responsible for making sure goods shipped are
          acceptable for entry into the destination country. All charges for
          shipment to and return from countries where entry is not permitted
          shall be the Customer’s responsibility.
        </li>
        <li>
          Customer also understands that POD may not be available for all the
          cross-national borders/ international shipments as the same may be
          routed by the courier partner through local post.
        </li>
        <li>
          Proof of Delivery: No proof of delivery will be provided in case of
          international shipments. The final status shared by Service Provider
          will be considered as the final status.
        </li>
        <li>
          Returns: There is no provision of Returns in international shipments.
          Undelivered shipments will be disposed of after a certain cut off
          time, as decided by Service Provider.
        </li>
        <li>
          Delivery: In some cases, there will be chances that physical delivery
          to the buyer won’t be possible, shipment will be delivered in an open
          porch or mailbox or either buyer must do self-collection from the
          access pickup point of the carrier, and these cases will be closed as
          delivered on the system.
        </li>
        <li>
          Cash on Delivery: Cash on Delivery facility is not available for
          international shipments.
        </li>
      </ul>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        Annexure IV Fulfilment and Warehousing
      </h2>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        Annexure V List of Banned or Prohibited Items (Indicative)
      </h2>
      <h3 className="font-Open text-base font-semibold leading-[22px]">
        Dangerous Products
      </h3>
      <ul>
        <li>Oil-Based Paint and Thinners (Flammable Liquids)</li>
        <li>Industrial Solvents</li>
        <li>Insecticides, Garden Chemicals (Fertilizers, Poisons)</li>
        <li>Lithium Batteries</li>
        <li>Magnetized Materials</li>
        <li>
          Machinery (Chain Saws, Outboard Engines Containing Fuel or That Have
          Contained Fuel)
        </li>
        <li>Fuel For Camp Stoves, Lanterns, Torches or Heating Elements</li>
        <li>Automobile Batteries</li>
        <li>Infectious Substances</li>
        <li>Any Compound, Liquid or Gas That Has Toxic Characteristics</li>
        <li>Bleach</li>
        <li>Flammable Adhesives</li>
        <li>Arms And Ammunitions</li>
        <li>Dry Ice (Carbon Dioxide, Solid)</li>
        <li>Pressurized Containers</li>
        <li>Narcotic Substances</li>
      </ul>
      <h3 className="font-Open text-base font-semibold leading-[22px]">
        Restricted Items
      </h3>
      <ul>
        <li>Precious Stones, Gems, And Jewellery</li>
        <li>Uncrossed (Bearer) Drafts / Cheque, Currency, And Coins</li>
        <li>Poison</li>
        <li>Firearms, Explosives, And Military Equipment.</li>
        <li>Hazardous And Radioactive Material</li>
        <li>Foodstuff And Liquor</li>
        <li>Any Pornographic Material</li>
        <li>Hazardous Chemical Item</li>
      </ul>

      <h2 className="font-Open text-base font-semibold leading-[22px]">
        Postpaid terms and conditions
      </h2>
      <h3>Applicable in case of Postpaid customers</h3>
      <p className="font-Open text-base font-normal leading-5">
        Customer has requested the service provider to allow postpaid service
        for their account wherein a security deposit of Rs. _ has been provided
        by the user. The customer hereby agrees that a limit of Rs. _ shall be
        provided to his/her account, and the amount utilized for shipping or
        other service purpose shall be binding on the user to be paid in cash or
        by adjusting the COD remittances.
      </p>
      <p className="font-Open text-base font-normal leading-5">
        User also understands that once the above-mentioned limit is utilized
        he/she needs to pay that amount to continue using the service, and the
        said paid amount shall be adjusted against the invoice raised for using
        the services.
      </p>
      <p className="font-Open text-base font-normal leading-5">
        In case of non-payment of the amount below cases should proceed with,
        which will be ruled as per the below provision:
      </p>
      <ul>
        <li>
          If the invoice amount is more than the credit in your account: If the
          outstanding is more than the limit provided and any amount paid
          earlier, the same shall be adjusted from the COD remittances which are
          due to be paid by <strong>{COMPANY_NAME || "Shipyaari"}</strong> to
          the user. Post adjustment, the limit of the user shall be released,
          and the user then can start using the service again. If the user
          specifically requests that amount not be deducted from COD remittance,
          then such an outstanding amount shall be kept on reserve from the
          remittance and shall be released only when such outstanding is paid in
          the upcoming cycle, in such case the limit of the user will not be
          released unless the outstanding is paid.
        </li>
        <li>
          If the Invoice amount is less than the credit in your account: If the
          outstanding amount is less than the amount available for remittance,
          then the outstanding shall be adjusted against the remittance due to
          the user, and the balance amount shall be paid to the user, the limit
          of the user shall be released, and the user can start using the
          service. If the user specifically mentions that COD remittances should
          not be adjusted against the outstanding, then the same shall be kept
          on hold or reserve and shall be released as and when the amount of
          outstanding is paid; in such case, the limit of the user will not be
          released unless the outstanding is paid.
        </li>
      </ul>
      <p className="font-Open text-base font-normal leading-5">
        The service provider keeping faith on the user shall provide a limit to
        use the dashboard. The user at all times should maintain such trust and
        faith. If in case the user wants his limit to be increased, the same can
        be done by increasing the security deposit or by mutual understanding.
      </p>
      <p className="font-Open text-base font-normal leading-5">
        In no case, the outstanding shall be due for more than 7 days; if in
        case the due date of payment is missed, then it shall be construed that
        the user has given implicit permission to adjust the amount from the COD
        remittance, and post adjustment, the limit of the user shall be
        released. Any dispute in the invoice shall be settled by issuing Credit
        Notes (CN) or Debit Notes (DN), such disputes shall take its due course
        to get resolved, and in such case, bills generated and due shall be paid
        in whole; such CN or DN shall be utilized or charged, whichever the case
        may be, in the next invoice.
      </p>
      <p className="font-Open text-base font-normal leading-5">
        User shall indemnify, defend and hold harmless the Service Provider (its
        directors, employees, authorized representatives, distribution agents,
        and all their employees) (“Indemnified Person”) from and against any and
        all losses, liabilities, claims, damages, interest, penalties, costs and
        expenses, including reasonable legal fees and disbursements in relation
        thereto incurred or suffered by User, as a result of, arising from, or
        in connection with or relating to any matter inconsistent with, or any
        breach or inaccuracy of any representation, warranty, covenant, term or
        agreement made by the User.
      </p>
      <p className="font-Open text-base font-normal leading-5">
        Each Party represents and warrants to the other Party that:
      </p>
      <ul>
        <li>
          It has all necessary rights, powers, and authority to enter into and
          perform this Agreement.
        </li>
        <li>
          The entrance and performance of this Agreement by it shall not violate
          any applicable law and shall not breach any agreement, covenant, court
          order, judgment, or decree to which such Party or by which it is
          bound.
        </li>
      </ul>
      <br />
      <h2 className="font-Open text-base font-semibold leading-[22px]">
        Letter of Intimation
      </h2>
      <br />
      <p className="text-center underline font-Open text-base font-normal leading-5">
        To Whomsoever It May Concern,
      </p>
      <p className="font-Open text-base font-normal leading-5">
        This is to inform that I/User has approached{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {WHITE_COMPANYFULLNAME || "AVN Business solutions PVT Ltd."}{" "}
        </strong>
        (Hereinafter Referred to as "
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>
        ”) to open a logistics services account as they cater to many
        value-added services which are not available from a particular direct
        courier partner in some of the cases as solutions provided by them are
        single window solutions.
      </p>
      <p className="font-Open text-base font-normal leading-5">
        I state that the services provided by a direct carrier as compared to
        those provided by{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        are different & as{" "}
        <strong className="font-Open text-base font-semibold leading-[22px]">
          {COMPANY_NAME || "Shipyaari"}
        </strong>{" "}
        is enabling more comprehensive logistics solutions which are essential
        for business growth.
      </p>
    </div>
  );
};

export default LogisticsServicesAgreement;
